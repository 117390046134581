import { _x } from '@wordpress/i18n';

document.addEventListener('DOMContentLoaded', function () {
  const toggleButton = document.querySelector(
    '.quick-access-block__toggle-button'
  );
  const quickAccessBlock = document.querySelector('.quick-access-block');

  toggleButton.addEventListener('click', function () {
    quickAccessBlock.classList.toggle('expanded');
    toggleButton.classList.toggle('quick-access-block__toggle-button--close');
  });

  const quickAccessGrid = document.querySelector('.quick-access-block__grid');
  const quickAccessItems = Array.from(
    quickAccessGrid.querySelectorAll('.quick-access-item:nth-child(n+5)')
  );
  let showMoreButton;

  function createShowMoreButton() {
    showMoreButton = document.createElement('button');
    showMoreButton.textContent = _x('Show more', 'Button text', 'vtx');
    showMoreButton.className =
      'show-more-button btn quick-access-block__show-more-button';
    quickAccessGrid.parentNode.insertBefore(
      showMoreButton,
      quickAccessGrid.nextSibling
    );

    showMoreButton.addEventListener('click', function () {
      const isExpanded = showMoreButton.classList.toggle('expanded');
      quickAccessItems.forEach((item) => {
        item.style.display = isExpanded ? 'flex' : 'none';
      });
      showMoreButton.textContent = isExpanded
        ? _x('Show less', 'Button text', 'vtx')
        : _x('Show more', 'Button text', 'vtx');
    });
  }

  function handleResize() {
    if (window.innerWidth < 992) {
      if (!showMoreButton) {
        createShowMoreButton();
      }
      quickAccessItems.forEach((item) => {
        item.style.display =
          showMoreButton && showMoreButton.classList.contains('expanded')
            ? 'flex'
            : 'none';
      });
    } else if (showMoreButton) {
      showMoreButton.remove();
      showMoreButton = null;
      quickAccessItems.forEach((item) => {
        item.style.display = 'flex';
      });
    }
  }

  window.addEventListener('resize', handleResize);
  handleResize(); // Initial check
});
